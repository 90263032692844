import React, { useEffect, useState } from "react";
import { Route } from "react-router";
import { BrowserRouter, Switch, Redirect } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./components/Home";
// import Counter from './components/Counter';
// import FetchData from './components/FetchData';
import Auth from "./components/Auth";
import Form from "./components/Forms/Form";
import "./custom.css";
import { ErrorBoundary } from "react-error-boundary";
import axios from "axios";
import HTAASupport from "./components/Help/EmailSupport";
import ContactUs from "./components/Help/ContactUs";
import AdminLanding from "./components/Admin/Landing";
import AdminGrid from "./components/Table/AdminGrid";
import AccountSwitch from "./components/Dashboard/AccountSwitch";
import Dashboard from "./components/Dashboard/dashboard";
import Logout from "./components/Logout/Logout";
import Login from "./components/Logout/Login";
import MoreClientNews from "./components/ClientNewsArticles/moreClientNews";
import ClientNewsDetails from "./components/ClientNewsArticles/clientNewsDetails";
import "./content/shotgun/fonts/noto-sans-v14-700.woff2";
import "./content/shotgun/fonts/noto-sans-v14-700italic.woff2";
import "./content/shotgun/fonts/noto-sans-v14-italic.woff2";
import "./content/shotgun/fonts/noto-sans-v14-regular.woff2";
import CompareRecords from "./components/Dashboard/CompareRecords";
import "bootstrap/dist/css/bootstrap.min.css";
import NewModal from "./components/Popup/NewModal";
import WorkInProgressPage from "./components/WorkInProgressPage";
import ProductProfile from "./components/ProductProfile/ProductProfile";
import InternalServerErrorPage from "./components/ErrorPages/internalServerError";
import UnauthorizedPage from "./components/ErrorPages/Unauthorized";
import AdminSavedSearchAlert from "./components/Admin/AdminSavedSearchAlert";
import AdminSavedSearchDeactive from "./components/Admin/AdminSavedSearchDeactive";
import Instructions from "./components/Forms/Admin/Instructions";
import AccessDenied from "./components/Logout/AccessDenied";
import PrivateRoute from "./PrivateRoute";


function getToken() {
  const tokenString = localStorage.getItem("token");
  if (!tokenString) return null;
  return tokenString;
}

const LayoutDiv = () => {
  const roleId = localStorage.getItem("CurrRoleId");
  return (<>
    <Layout>
    </Layout>
    <PrivateRoute exact path="/" component={Home} />
    <PrivateRoute path="/cb" component={Auth} ></PrivateRoute>
    <PrivateRoute path="/form/:dataSourceID/:recordID?" component={Form} ></PrivateRoute>
    <PrivateRoute path="/view/:dataSourceID/:recordID" component={Form} ></PrivateRoute>
    <PrivateRoute path="/ViewVersion/:dataSourceID/:recordID/:versionID" component={Form} ></PrivateRoute>
    <PrivateRoute path="/admin/form/:dataSourceID/:recordID?" component={() => (Number(roleId) === 1 ? (<Form></Form>) : (<Redirect to="/Unauthorized" />))}></PrivateRoute>
    <PrivateRoute path="/admin/confirm/:dataSourceID/:recordID?" component={Form} />
    <PrivateRoute path="/admin/fieldcriteria/:dataSourceID/:fieldCriteriaDatasourceID/:fieldCriteriaID/:recordID?" component={() => (Number(roleId) === 1 ? (<Form />) : (<Redirect to="/Unauthorized" />))}></PrivateRoute>
    <PrivateRoute path="/news/list" component={() => (Number(roleId) === 1 ? (<MoreClientNews />) : (<Redirect to="/Unauthorized" />))}></PrivateRoute>
    <PrivateRoute path="/admin/instructions/:recordID" component={() => (Number(roleId) === 1 ? (<Instructions />) : (<Redirect to="/Unauthorized" />))}></PrivateRoute>
    <PrivateRoute path="/admin/list/:dataSourceID/:recordID?" component={() => (Number(roleId) === 1 ? (<AdminLanding />) : (<Redirect to="/Unauthorized" />))}></PrivateRoute>
    <PrivateRoute path="/admin/clientgroup/create/:dataSourceID/:clientID/:recordID?" component={() => (Number(roleId) === 1 ? (<Form />) : (<Redirect to="/Unauthorized" />))}></PrivateRoute>
    <PrivateRoute path="/admin/lookupItem/create/:dataSourceID/:lookupID/:recordID?" component={() => (Number(roleId) === 1 ? (<Form />) : (<Redirect to="/Unauthorized" />))}></PrivateRoute>
    <PrivateRoute
      path="/reports/home"
      component={(props) => <AdminGrid dataSourceID={270} recordID={0} isAdminData={false} Title={""} />}
    ></PrivateRoute>
    <PrivateRoute path="/accountSwitch/:recordID/:roleId?" component={AccountSwitch} ></PrivateRoute>
    <PrivateRoute path="/savedSearch/:savedSearchId" component={Dashboard} ></PrivateRoute>
    <PrivateRoute path="/savedSelection/:savedSelectionId" component={Dashboard} ></PrivateRoute>
    <PrivateRoute path="/help" component={ContactUs} />
    <PrivateRoute
      path="/:dataSourceID/CompareRecordsViews"
      component={CompareRecords}
    ></PrivateRoute>
    <Route path="/LoggedOut" component={Logout} />
    <Route path="/AccessDenied" component={AccessDenied} />
    <Route path="/Login" component={Login} ></Route>
    <PrivateRoute path="/AdminSavedSearchAlert" component={() => (Number(roleId) === 1 ? (<AdminSavedSearchAlert />) : (<Redirect to="/Unauthorized" />))}></PrivateRoute>
    <PrivateRoute path="/AdminSavedSearchDeactive" component={() => (Number(roleId) === 1 ? (<AdminSavedSearchDeactive />) : (<Redirect to="/Unauthorized" />))}></PrivateRoute>
    <PrivateRoute path="/ClientNewsList" component={MoreClientNews} ></PrivateRoute>
    <PrivateRoute
      path="/ClientNewsArticle/:clientnewsId?"
      component={ClientNewsDetails}
    ></PrivateRoute>
    <PrivateRoute path="/Search/:searchName" component={Home} />
    <PrivateRoute path="/WorkInProgress" component={WorkInProgressPage} />
    <PrivateRoute path="/profiler/profile" component={ProductProfile} ></PrivateRoute>
    <Route path="/error!" component={InternalServerErrorPage} />
    <Route path="/Unauthorized" component={UnauthorizedPage} />
  </>
  );
};

function MyFallbackComponent({ error, resetErrorBoundary }:any) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
}

function App() {
  const token = getToken();


  return (
    <React.Fragment>
      <ErrorBoundary
        FallbackComponent={MyFallbackComponent}
        onError={(error, error_info) => {
          var browserName = (function (agent) {
            switch (true) {
              case agent.indexOf("edge") > -1:
                return "MS Edge";
              case agent.indexOf("edg/") > -1:
                return "Edge ( chromium based)";
              case agent.indexOf("opr") > -1:
                return "Opera";
              case agent.indexOf("chrome") > -1:
                return "Chrome";
              case agent.indexOf("trident") > -1:
                return "MS IE";
              case agent.indexOf("firefox") > -1:
                return "Mozilla Firefox";
              case agent.indexOf("safari") > -1:
                return "Safari";
              default:
                return "other";
            }
          })(window.navigator.userAgent.toLowerCase());
          axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + "/api/logerror",
            data: {
              date_time: Date(),
              err_msg: String((error as Error).stack),
              error_info: String((error as Error).message),
              browser_name: browserName,
            },
            headers: {
              "Content-Type": "application/json",
            },
          }).then((response) => {
            console.log(response);
          });
        }}
        onReset={() => {
          // reset the state of your app
        }}
      >
        <LayoutDiv />
        <NewModal/>
        
      </ErrorBoundary>
    </React.Fragment>
  );
}

export default App;
