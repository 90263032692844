import React, { useEffect, useState } from "react";
import "./Comments.css";
import { useAppSelector } from "../../store/hooks";
import { Comment } from "../../store/DataSourceMetaData";
import { useFetchLookupItemsQuery } from "../../store/LookupApiSlice";
import { RiDeleteBinLine } from "react-icons/ri";
import Tooltip from "@mui/material/Tooltip";
import DeleteCall from "../service/deleteCall";
import ClearIcon from "@mui/icons-material/Clear";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { styled } from '@mui/material/styles';

interface CommentsProps {
  commentList: Comment[];
  clientList: { label: string; ID: number }[];
  commentTableName?: string;
  findComments?: Function;
  view?: string;
  recordTitle?: string;
  retrieveDataToUpdate?: Function;
}

type UserObj = {
  ID: number;
  Lookup_Item_Parent_ID: number;
  Parent_ID: number;
  SortOrder: number;
  Value: string;
};

const Comments = (props: CommentsProps) => {
  const {
    commentList,
    clientList,
    commentTableName,
    findComments,
    view,
    recordTitle,
    retrieveDataToUpdate,
  } = props;
  const [userList, setUserList] = useState<UserObj[]>([]);
  const currRole = localStorage.getItem("CurrRoleId");

  const borderTop = {
    borderTop: "1px solid #ddd",
    borderLeft: "1px solid #ddd",
    borderRight: "1px solid #ddd",
  };
  const fullBorder = {
    border: "1px solid #ddd",
  };

  const dataSourceId = useAppSelector(
    (state) => state.search.selectedDatasourceId
  );

  // Methods
  const { data, isLoading } = useFetchLookupItemsQuery({
    lookup_id: 130,
    datasource_id: dataSourceId,
  });

  useEffect(() => {
    if (data && data.length > 0) {
      setUserList(data);
    }
  }, [isLoading]);

  const findUser = (id: number) => {
    for (let user of userList) {
      if (user.ID === id) {
        return user.Value;
      }
    }
  };

  const findVisibility = (id: number, clientId: number) => {
    if (id === 1) return "All Users";
    else if (id === 2) return "IQVIA Internal";
    else if (id === 0) return findClient(clientId);
  };

  const findClient = (clientID: number) => {
    for (let info of clientList) {
      if (info.ID === clientID) return info.label;
    }
  };

  const findDate = (date: any) => {
    let splitDateAndTime = date.split("T");
    let fullDate = splitDateAndTime[0].split("-");
    return `${fullDate[2]}/${fullDate[1]}/${fullDate[0]}`;
  };

  const deleteComment = async (id: number) => {
    if (!!id && view !== "compare" && view !== "read") {
      let response = await DeleteCall(
        `/api/deleteComment/${commentTableName}/${id}/`
      );
      if (response) findComments(commentTableName);
    }
  };

  const updatePricingView = (item: any) => {
    retrieveDataToUpdate(item);
  }

  return (
    <div
      id="section-comment"
      className ="commentsContainer"
      // className={
      //   view !== "pricingView"
      //     ? "commentsContainer topBorder"
      //     : "commentsContainer"
      // }
    >
      {view !== "pricingView" && (
        <h4 className="title panel-title" style={{fontSize:"12px"}}>Comments</h4>
      )}
      {recordTitle && <div> {recordTitle} : </div>}
      {commentList.length > 0 && (
        <div className="commentsBox">
          {commentList.map((el, key) => (
            <div
              style={key + 1 !== commentList.length ? borderTop : fullBorder}
              className="comment row"
              key={key}
            >
              <div className="col-3" style={{fontSize:"12px"}}>
                <div> {findUser(el.CreatedBy)} </div>
                <div>
                  <span>
                    {" "}
                    {findVisibility(el.Audience_Type, el.Client_ID)}{" "}
                  </span>
                  <span>-</span>
                  <span> {findDate(el.CreatedDate)} </span>
                </div>
              </div>
              <div className="col-8" style={{fontSize:"12px"}}>{el.Comment}</div>
              {view !== "compare" &&
                !(Number(currRole) > 4 && Number(currRole) < 8) &&
                view !== "read" &&
                view !== "pricingView" && (
                  <div className="col-1 trash">
                    <Tooltip title="Delete" placement="bottom-end">
                      <RiDeleteBinLine
                        style={{ cursor: "pointer",fontSize:"medium" }}
                        onClick={() => deleteComment(el.ID)}
                      />
                    </Tooltip>
                  </div>
                )}
              {view === "pricingView" && (
                <div className="col-1 icons iqvia-color">
                  <div>
                    <Tooltip title="Edit" placement="bottom-end">
                      <FontAwesomeIcon
                        icon={faPenToSquare}
                        style={{ cursor: "pointer" }}
                        onClick={() => updatePricingView(el)}
                      />
                    </Tooltip>
                  </div>
                  <div style={{ marginLeft: 7 }}>
                    <Tooltip title="Delete" placement="bottom-end">
                      <ClearIcon
                        style={{ cursor: "pointer" }}
                        fontSize="large"
                      />
                    </Tooltip>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
      <hr style={{borderBottom:"1px solid gray"}} />
    </div>
  );
};

export default Comments;
