import React, { useEffect, useState } from "react";
import { Field, Section } from "../../store/DataSourceMetaData";
import Accordion from "react-bootstrap/Accordion";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

interface advanceFilterProps {
  currentSearchDataSource: number;
  sections: Section[];
  fields: Field[];
  aFields: Field[];
  filterChanged: (value: any) => void;
  defaultActiveAcc: string[];
}

export default function AdvanceFilter(props: advanceFilterProps) {
  let {
    currentSearchDataSource,
    sections,
    fields,
    aFields,
    filterChanged,
    defaultActiveAcc,
  } = props;

  const [activeKeys, setActiveKeys] = useState(defaultActiveAcc);
  const [filteredSections, setFilteredSections] = useState(sections);

  useEffect(() => {

    const tempSections = sections.filter((s) => {
      return aFields.some((f) => f.sectionId === s.id);
    });
    setFilteredSections(tempSections);

  }, [sections, aFields]);

  useEffect(() => {
    setActiveKeys(defaultActiveAcc);
  }, [defaultActiveAcc]);

  const handleToggleClick = (itemKey: string) => {
    const index = activeKeys.indexOf(itemKey);
    if (index > -1) {
      activeKeys.splice(index, 1);
      setActiveKeys([...activeKeys]);
    } else {
      setActiveKeys(activeKeys.concat(itemKey));
    }
  };

  const handleSelect = (eventKey: AccordionEventKey) =>
    setActiveKeys(eventKey as string[]);

  return (
    <>
      <div>
        <Accordion alwaysOpen activeKey={activeKeys} onSelect={handleSelect}>
          {filteredSections.map((el, index) => (
            <div key={index}>
              <Accordion.Item eventKey={el.name}>
                <Accordion.Header onClick={() => handleToggleClick(el.name)}>
                  {activeKeys.includes(el.name) ? (
                    <KeyboardArrowDownIcon style={{ color: "#00aae7", fontSize: "35px" }}  />
                  ) : (
                    <KeyboardArrowRightIcon style={{ color: "#00aae7", fontSize: "35px" }}  />
                  )}
                  {el.name}
                </Accordion.Header>
                <Accordion.Body>
                  <div className="row">
                    {aFields
                      .filter((x) => x.sectionId == el.id)
                      .map((item: any, index: number) => (
                        <div className="col-md-4" key={index}>
                          <div className="form-check">
                            <input
                              type="checkbox"
                              checked={fields.some((f) => f.name == item.name)}
                              style={{ color: "#005486", cursor: "pointer" }}
                              onChange={(e) => {
                                if (!fields.some((f) => f.name == item.name))
                                  filterChanged([...fields, item]);
                                else {
                                  fields = fields.filter(
                                    (f) => f.name != item.name
                                  );
                                  filterChanged([...fields]);
                                }
                                // aField = fields;
                              }}
                            />
                            &nbsp;
                            <label
                              style={{
                                cursor: "pointer",
                                color: fields.some((f) => f.name == item.name)
                                  ? "#00aae7"
                                  : "#393939",
                              }}
                            >
                              {item.label}
                            </label>
                          </div>
                        </div>
                      ))}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </div>
          ))}
        </Accordion>
      </div>
    </>
  );
}
