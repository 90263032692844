import React, { useEffect } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import Cookies from 'js-cookie';
import AutoLagoutTimer from "./AutoLagoutTimer";

interface PrivateRouteProps extends RouteProps {
  component: React.ComponentType<any>;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, ...rest }) => {
  const isEmail = Cookies.get('email');
  
  return (
    <Route
      {...rest}
      render={props =>
            isEmail && isEmail !== "" ? (
          // <Component {...props} />
          <AutoLagoutTimer ComposedClass={Component} />
        ) : (
          <Redirect to={{ pathname: '/Login', state: { from: props.location } }} />
        )
      }
    />
  );
};

export default PrivateRoute;