import { createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {Section, Field, FieldCriteria} from './DataSourceMetaData';
import { emptySplitApi } from './emptyApiSlice';

export const apiSlice_DataSource = emptySplitApi.injectEndpoints({
// export const apiSlice_DataSource = createApi({
//     reducerPath: 'api',
//     baseQuery: fetchBaseQuery({
//         baseUrl: 'http://localhost:3000/',
//     }),
    endpoints(build) {
        return{
            fetchSection: build.mutation<Section[], {dataSourceID: number, roleId: number}>({
                query: (args) => {
                    const {dataSourceID, roleId} = args;
                    return {url : `api/admin/section/ds/${dataSourceID}/${roleId}`};
                }
            }),  
            fetchField: build.mutation<Field[], {dataSourceID: number}>({
                query: (args) => {
                    const {dataSourceID} = args;
                    
                    return { url :`api/admin/field/ds/${dataSourceID}`};
                }
            }),
            fetchFieldCriteria: build.mutation<FieldCriteria[], {dataSourceID: number}>({

                query: (args)=>{
                    const {dataSourceID} = args;
                    return {url : `api/admin/fieldCriteria/ds/${dataSourceID}`};
                }
            }),
            // fetchSections: build.mutation({
            //     query: ({dataSourceID}) => ({
            //         url: `/admin/section/ds/${dataSourceID}`,
            //         method: 'GET',
            //     }
            //     ),
            //   }),
            fetchSearchField: build.mutation<Field[], {dataSourceID: number, isAdvanceSearch: boolean, roleId: number}>({
                query: (args) => {
                    const {dataSourceID, isAdvanceSearch, roleId} = args;
                    return {url : `api/admin/basicSearchField/${dataSourceID}/${isAdvanceSearch}/${roleId}`};
                }
            }),   
            subscriptions: build.query<string, {user_id: string}>({
                query: (args) =>{
                    const {user_id} = args;

                    return {url : `api/subscriptions/${user_id}`};
                }
            })        
        }
    },
});

export const { useFetchSectionMutation, useFetchFieldMutation, useFetchSearchFieldMutation, useFetchFieldCriteriaMutation, useSubscriptionsQuery
    // useFetchSectionsMutation
 } = apiSlice_DataSource;
