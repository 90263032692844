import * as React from 'react';
import { Container } from 'reactstrap';
import NavMenu from './NavMenu';
import Cookies from 'js-cookie';

export default class Layout extends React.PureComponent<{}, { children?: React.ReactNode }> {
    //  location = useLocation();
    public render() {
        const isEmail = Cookies.get('email');
        const url = new URL(window.location.href);
        return (
            <React.Fragment>
               {/* { <NavMenu />} */}
               {(url.pathname !== '/Login' && url.pathname !== '/LoggedOut' && url.pathname !== '/cb' && url.pathname !== '/error!' && url.pathname !== '/AccessDenied') && isEmail &&<NavMenu />}
                <Container>
                    {this.props.children}
                </Container>
            </React.Fragment>
        );
    }
}