import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { IDoesFilterPassParams, IFilterParams } from "ag-grid-community";
import Spinner from "react-bootstrap/Spinner";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setSelectedColumnFiters, setFilterColumnTypes, setColumnFilters, setColumnFilterApplied } from "../../store/SearchSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { useGetColumnFilterValuesMutation } from "../../store/SearchResultApiSlice";
import { convert } from "html-to-text";

interface MultiColumnFilterParams extends IFilterParams {
  datasourcId?: number;
  columnType?: string | null;
  closeFilterPopup?: Function | null;
}
export default forwardRef((props: MultiColumnFilterParams, ref) => {
  const [filterText, setFilterText] = useState<string>("");
  const [selectedFilters, setSelectedFilters] = useState<any[]>([]);
  const [allFilters, setAllFilters] = useState<any[]>([]);

  const { api, colDef, column, columnApi, context, columnType, datasourcId = 0 } =
    props;

  const isDate = columnType && columnType === "QHTASearch_mRenderDate";

  const dispatch = useAppDispatch();
  const selectedColFilters = useAppSelector((state) => state.search.selectedColumnFilter);
  const allColumnfilterValues = useAppSelector(
    (state) => state.search.columnFilterData
  );
  const columnfilterValues = useAppSelector(
    (state) => state.search.columnFilterData[column.colId]
  );

  const filterColumnTypes = useAppSelector((state) => state.search.filterColumnTypes);
  const currentAccountId = useAppSelector(
    (state) => state.userProfile.accountId
  );
  const activeColumnFilter = useAppSelector(
    (state) => state.search.activeColumnFilter);

  const [getColumnFilterValues, { isLoading: isColumnLoading }] = useGetColumnFilterValuesMutation();
  const GetColumFilterForSearch = async (payload: any) => {
    
    const colFilter = await getColumnFilterValues(payload);
    const valueArray = colFilter.data[column.colId];
    const values = [...new Set([...valueArray])];
    if(selectedColFilters.hasOwnProperty(column.colId)){
      let selectedValues = selectedColFilters[column.colId];
      if(selectedColFilters[column.colId].includes(null) && selectedColFilters[column.colId].includes('')){
        selectedValues = selectedValues.filter((x) => x!=='');
      }
        // Create a set from array2 for faster lookup
        const setArray2 = new Set(selectedValues);
      
        // Separate elements that are in array2 and not in array2
        const result = [
          ...selectedValues.filter(item => setArray2.has(item)),  // elements from array2 that exist in array1
          ...values.filter((item: any) => !setArray2.has(item))  // remaining elements in array1 that are not in array2
        ];

      const filterValues = {...allColumnfilterValues, [column.colId]: result};
      

    setAllFilters(result);
    dispatch(setColumnFilters({data: filterValues, from: "SearchTable"}));
    }else{
      const filterValues = {...allColumnfilterValues, [column.colId]: values};
      setAllFilters(values);
      dispatch(setColumnFilters({data: filterValues, from: "SearchTable"}));
    }
    
  }
  useEffect(()=> {
    // if(!allColumnfilterValues.hasOwnProperty(column.colId)){
    if(activeColumnFilter && activeColumnFilter === column.colId && !allColumnfilterValues.hasOwnProperty(column.colId)){
      //fetch columnfilterValues here
      GetColumFilterForSearch({
        accountID: currentAccountId,
        datasourceId: datasourcId,
        columns: [`${column.colId}`],
        colFieldRenderMethod: [`${columnType}`]
      });
    }    
    //set selected values
    if(selectedColFilters.hasOwnProperty(column.colId)){
      setSelectedFilters(selectedColFilters[column.colId]);
      if(selectedColFilters[column.colId].includes(null) || selectedColFilters[column.colId].includes('')){
        setBlankCheck(true);
      }
      props.filterChangedCallback();
    }
      
  },[activeColumnFilter]);

  useEffect(() => {
    if (allFilters && allFilters.length == 0) {
      setAllFilters(columnfilterValues);   
    }
  }, [columnfilterValues]);

  const addOneDay = (date: Date) => {
    date.setDate(date.getDate() + 1);

    return date;
  };

  useImperativeHandle(ref, () => {
    return {
      isFilterActive() {
        if([1,2,3,4,5,6,7,20,23,25].includes(datasourcId)){
          const localFilter = selectedFilters.length > 0;
          const filtersForColumn = selectedColFilters[column.colId]?.length > 0;
          return localFilter || filtersForColumn;
          // filtersForColumn && filtersForColumn.length > 0;
        }
        return selectedFilters.length > 0;
      },

      doesFilterPass(params: IDoesFilterPassParams) {
        const { node } = params;

        if (filterText == "~") {
          setTimeout(() => {
            setFilterText("");
          }, 500);
        }
        if (selectedFilters.length == 0) return true;
        
        let passed = true;
        function convertDateFormat(dateString: string) {
          const [day, month, year] = dateString.split('/');
          return `${year}-${month}-${day}`;
      }
        if(isDate){
          passed=selectedFilters.some((x) => {
            const dateString = x !== null ? convertDateFormat(x) : null;
             return dateString == node.data[column.colId]
            });
          }else{
          passed = selectedFilters.some((x) => {
            if (x === null || x === '') {
              return node.data[ column.colId ] === '' || node.data[ column.colId ] === null;
            }
            else if (typeof(x) == "string"){
              const value = node.data[ column.colId ]?.replace(/(\r\n|\n|\r)/gm, " ");
              // .replace(/\s+/g, '');
              const y = x
              // .replace(/\s+/g, '');
              if (value == y)
                return true
            } 
            return typeof(x)!= "boolean" && !isNaN(x) ? x == node.data[column.colId] : x === node.data[column.colId]});
        }
        return passed;     
    },

      getModel() {
          if (!this.isFilterActive()) {
            return null;
          }
  
          let filterVal = "";
  
          filterVal =
            filterText == "true" || filterText == "false"
              ? JSON.parse(filterText.toLowerCase())
              : filterText?.trim();
  
          return { value: filterVal };        
      },

      setModel(model: any) {
                  // setFilterText(model == null ? null : model.value);
          setSelectedFilters(model?.value || []);
        
      },

      onFilterChanged(){
        props.filterChangedCallback();
      }
    };
  });

  const onChange = (value: any) => {
    setFilterText(value);
  };

  const [selectedFiltersData, setSelectedFilterData] = useState({});
  const [filtersTypes, setFilterTypes] = useState({});

  useEffect(() => {
    
    const selectedValuesWithoutEmpltyString = selectedFilters.filter(x => x !== '');
    if(filterText && selectedFilters.length > 0){
      const filteredSelected = allFilters.map((val) => {
        if (val === null || val === "" || val === "[Blank]") {
          return null;
        } else {
          return val == "true" || val == "false"
            ? JSON.parse(val.toLowerCase())
            : (typeof (val) == "string" ? val.trim() : val);
        }
      })
      .filter(
        (x) =>          
          (filterText &&
            (x != undefined && x != null) &&
            x
              .toString()
              .toLowerCase()
              .indexOf(filterText.toLowerCase()) >= 0)
      );

      if("[blank]".includes(filterText.toLowerCase()) && allFilters.includes(null) && !filteredSelected.includes(null)){
        filteredSelected.push(null);
      }
      if(filteredSelected.length != 0 && selectedFilters.length >= filteredSelected.length){ // incase when you filter and do select all on multiple value 2018, 2017
        let setAll = true;
          const set1 = new Set(filteredSelected);
          const set2 = new Set(selectedFilters);
          for (let item of set1) {
              if (!set2.has(item)){
                setAll = false;
              }
          }
               
        setCheckSelectAll(setAll);
      }else{
        setCheckSelectAll(false);
      }
        
    }
    else if(allFilters && allFilters.length > 0 && selectedValuesWithoutEmpltyString &&  selectedValuesWithoutEmpltyString.length === allFilters.length){
      setCheckSelectAll(true);
    } else{
      setCheckSelectAll(false);
    }
    if([1,2,3,4,5,6,7,20,23,25].includes(datasourcId)){
      let selectedFilterData = {...selectedColFilters};
      let filterTypes = {...filterColumnTypes};
      if(selectedFilters.length == 0){
        delete selectedFilterData[column.colId];
        delete filterTypes[column.colId];
      }else{
        selectedFilterData = {...selectedColFilters, [column.colId]: selectedFilters};
        filterTypes = {...filterTypes, [column.colId]: columnType};
      }
      setSelectedFilterData(selectedFilterData);
      setFilterTypes(filterTypes);
      // dispatch(setSelectedColumnFiters(selectedFilterData));
    }

    ![1,2,3,4,5,6,7,20,23,25].includes(datasourcId) && props.filterChangedCallback();
  }, [filterText, selectedFilters]);

  const applyFilter = () => {
    dispatch(setColumnFilterApplied(true));
    if([1,2,3,4,5,6,7,20,23,25].includes(datasourcId)){
      if(selectedFilters){
        let selectedValues = [...selectedFilters];
        if(selectedFilters.includes(null) && selectedFilters.includes('')){
          selectedValues = selectedValues.filter((x) => x!=='');
        }
        const setArray2 = new Set(selectedValues);
        const selectedCol = allFilters.filter(item => setArray2.has(item));
        const unselectedCol = allFilters.filter((item: any) => !setArray2.has(item)) ;
        
        const result = [
          ...selectedCol,  
          ...unselectedCol  
        ];

        const filterValues = {...allColumnfilterValues, [column.colId]: result};
        

        setAllFilters(result);
        dispatch(setColumnFilters({data: filterValues, from: "SearchTable"}));
        dispatch(setFilterColumnTypes(filtersTypes));
        dispatch(setSelectedColumnFiters(selectedFiltersData));
      }
      props.filterChangedCallback();
    }
    props.closeFilterPopup && props.closeFilterPopup();
  }
  const [checkSelectAll, setCheckSelectAll] = useState(false);

  const [blankCheck, setBlankCheck] = useState(false);

  const closeFilter = () => {
    props.closeFilterPopup && props.closeFilterPopup();
  };

  function formatNumber(input: any) {
    // Check if the input is a float
    if (input.includes('.')) {
        const decimalLength = input.split('.')[1].length;
        return parseFloat(input).toFixed(decimalLength);
    } else {
        return parseInt(input);
    }
  }


  return (
    <>
    {isColumnLoading && (
      <>
        <Spinner size="sm" animation="border" style={{ color: "#00aae7" }} />
        <h6>Loading filters...</h6>
      </>
    )}
    {!isColumnLoading && allFilters && (
      <div style={{ padding: 4, width: 200, backgroundColor: "white" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <button
          style={{
            margin: "2px 0px",
            color: "white",
            backgroundColor: "rgb(0, 170, 231)",
            border: "1px solid rgb(0, 170, 231)",
            borderRadius: "5px",
            lineHeight: "20px",
          }}
          onClick={() => {
            setCheckSelectAll(false);
            setBlankCheck(false);
            setSelectedFilters([]);
          }}
        >
          Clear
        </button>
        {
          [1,2,3,4,5,6,7,20,23,25].includes(datasourcId) && (
            <button
              style={{
                margin: "2px 0px",
                color: "white",
                backgroundColor: "rgb(0, 170, 231)",
                border: "1px solid rgb(0, 170, 231)",
                borderRadius: "5px",
                lineHeight: "20px",
              }}
              onClick={applyFilter}
            >
              Apply
            </button>
          )
        }
        {
          ![1,2,3,4,5,6,7,20,23,25].includes(datasourcId) && (
            <button
              style={{
                margin: "2px 0px",
                color: "white",
                backgroundColor: "rgb(0, 170, 231)",
                border: "1px solid rgb(0, 170, 231)",
                borderRadius: "5px",
                lineHeight: "20px",
              }}
              onClick={closeFilter}
            >
              Close
            </button>
          )
        }
        
      </div>
      <div>
        <input
          style={{ margin: "4 0 4 0" }}
          type="text"
          value={filterText}
          onChange={(e) => onChange(e.target.value)}
          placeholder="Search..."
          width="90%"
        />
        <FontAwesomeIcon
          onClick={() => {
            onChange("");
            setCheckSelectAll(false);
            setBlankCheck(false);
            setSelectedFilters([]);
          }}
          style={{
            fontSize: "14px",
            padding: "0px 5px",
            cursor: "pointer",
          }}
          icon={faRefresh as IconProp}
        />
      </div>
      <div style={{ marginTop: 20 }}>
        <ul>
          <li>
            <input
              type="checkbox"
              checked={checkSelectAll}
              onChange={(e) => {
                setCheckSelectAll(e.target.checked);
                if (e.target.checked) {
                  const filteredArr = allFilters.filter(
                    (x) =>
                      !filterText ||
                      (filterText &&
                        (x != undefined && x != null) &&
                        x
                          .toString()
                          .toLowerCase()
                          .indexOf(filterText.toLowerCase()) >= 0)
                  );

                  if("[blank]".includes(filterText.toLowerCase()) && allFilters.includes(null) && !filteredArr.includes(null)){
                    filteredArr.push(null);
                  }

                  // setSelectedFilters(
                  const selectedFiltered =  
                    filteredArr.map((val) => {
                      if (val === null || val === "" || val === "[Blank]") {
                        setBlankCheck(true);
                        return null;
                      } else {
                        return val === true || val === false
                          ? val
                          : (typeof (val) == "string" ? val.trim() : (isNaN(val)? val: formatNumber(val)));
                      }
                    });

                  const appendRemaining = selectedFilters.filter(element => !selectedFiltered.includes(element));
                    const allSelected = [...appendRemaining,...selectedFiltered];

                    setSelectedFilters([...allSelected]);
                  // );

                } else {
                  const filteredArr = allFilters.map((val) => {
                    if (val === null || val === "" || val === "[Blank]") {
                      return null;
                    } else {
                      return val == "true" || val == "false"
                        ? JSON.parse(val.toLowerCase())
                        : (typeof (val) == "string" ? val.toString().trim().replace(/\s+/g, '') : val);
                    }
                  })
                  .filter(
                    (x) =>
                      !filterText ||
                      (filterText &&
                        (x != undefined && x != null) &&
                        x
                          .toString()
                          .toLowerCase()
                          .indexOf(filterText.toLowerCase()) >= 0)
                  );
                  if("[blank]".includes(filterText.toLowerCase()) && allFilters.includes(null) && !filteredArr.includes(null)){
                    filteredArr.push(null);
                  }
                  if(filteredArr.length > 0){
                    setSelectedFilters(selectedFilters.filter(x => { 
                      if(x === null || x === "" || x === "[Blank]"){
                        setBlankCheck(false);
                        return (x !== null && x !== "");
                      }else{
                        let filterVal: any;
                      filterVal =
                      x == "true" || x == "false"
                          ? JSON.parse(x.toLowerCase())
                          : x;
                      filterVal = typeof (filterVal) == "string" ? filterVal.toString().trim().replace(/\s+/g, '') : filterVal;
                      return !filteredArr.includes(filterVal);
                      }
                      
                    }));
                  }else{
                    setBlankCheck(false);
                    setSelectedFilters([]);
                  }
                  
                }
              }}
            />{" "}
            Select all
          </li>
          {allFilters &&
            allFilters
              .filter(
                (x) =>
                  !filterText ||
                  (filterText &&
                    (((x != undefined && x != null) &&
                    x
                      .toString()
                      .toLowerCase()
                      .includes(filterText.toLowerCase()))
                      // .indexOf(filterText.toLowerCase()) >= 0) 
                      || ([null,'','[Blank]'].includes(x) && "[blank]".includes(filterText.toLocaleLowerCase()))) 
              ))
              .map((x) => (
                <li>
                  {" "}
                  <input
                    type="checkbox"
                    value={(x === null || x === "" ) ? null : x.toString()}
                    checked={x === null || x === "" ? (blankCheck) : (selectedFilters &&
                      selectedFilters.some((f) => {
                        if (isDate) {
                          return f == x;
                        } else {
                          if (f === null || f === ""){ !blankCheck && setBlankCheck(true); return [null, '', '[Blank]'].includes(x)}
                          else if(typeof(f) == "boolean") return f === x;
                          else{
                            return f.toString().trim().replace(/\s+/g, '') == x.toString().trim().replace(/\s+/g, '');
                          }
                        }

                      }))
                    }
                    onChange={(e) => {
                      const val = e.target.getAttribute("value");
                      let filterVal: any;                      
                      filterVal =
                        val == "true" || val == "false"
                          ? JSON.parse(val.toLowerCase())
                          : isNaN(val) ? val : formatNumber(val);
                      filterVal = typeof (filterVal) == "string" ? filterVal.trim() : filterVal;
                      if (e.target.checked) {
                        let sf = [...selectedFilters];
                        if (val == null || val == "" || val == "[Blank]") {
                          sf.push(null);
                          sf.push("");
                          setBlankCheck(true);
                        } else {
                          sf.push(filterVal);
                        }
                        setSelectedFilters(sf);
                      } else {
                        setCheckSelectAll(false);
                        if (val === null || val === "" || val === "[Blank]") {
                          setSelectedFilters(
                            selectedFilters.filter((s) => s !== null && s !== "")
                          );
                          setBlankCheck(false);
                        } else {
                          setSelectedFilters(
                            selectedFilters.filter((s) => {
                              if(typeof(filterVal) === "string" && s != null) 
                                return s.toString().trim().replace(/\s+/g, '') != filterVal.toString().trim().replace(/\s+/g, '');
                              else return s != filterVal;
                            })
                          );
                        }
                      }
                    }}
                  ></input>{" "}
                  {x === null || x === "" ? "[Blank]" : (typeof(x) == "string" ? `${convert(x)}` :`${x}`)}
                </li>
              ))}
        </ul>
      </div>
    </div>
    )}
    </>
  );
});
