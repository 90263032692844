import React from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { useHistory } from 'react-router'
import Cookies from 'js-cookie';
import { cal_sda } from './components/Logout/Login';
import { useAppDispatch } from './store/hooks';
import { setRedirectToAuth } from './store/UniversalValueSlice';

//session idle timeout 45 mins
const SESSION_IDEL_MINUTES = 5;

const AutoLagoutTimer = (props: any) => {
    const { ComposedClass } = props
    const history = useHistory()
    const dispatch = useAppDispatch();

    const handleOnIdle = (event: any) => {
        // SHOW YOUR MODAL HERE AND LAGOUT
        console.log('user is idle', event)
        console.log('last active', getLastActiveTime())
        dispatch(setRedirectToAuth(true));
        cal_sda();
    }

    const {getLastActiveTime } = useIdleTimer({
        timeout: 1000 * 60 * SESSION_IDEL_MINUTES,
        onIdle: handleOnIdle,
        debounce: 500,
    })

    return <ComposedClass />
}

export default AutoLagoutTimer;
